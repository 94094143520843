import 'prop-types';
import { makeSelectIsFirstPageAfterSsr, makeSelectReactRouterPageType, makeSelectCountry } from '@dbh/routing-redux';
import { makeSelectIsUserSignedIn, REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$1 } from '@dbh/user-area-redux';
import { makeSelectTemplateCode } from '@dbh/webpages-redux';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import _isNaN from 'lodash/isNaN';
import { fromJS, getIn, Map } from 'immutable';
import invariant from 'invariant';
import { API_IDS, API_IDS_TO_VERSIONS } from '@dbh/api-constants';
import { isServerSideRendering } from '@dbh/environment';
import { DEFAULT_LOCALE, DEFAULT_COUNTRY } from '@dbh/i18n';
import { requestWithBodyPOST } from '@dbh/request';
import makeApiUrl from '@dbh/make-api-url';
import { makeSelectApiUrl } from '@dbh/configuration-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$8 } from '@dbh/chat-widget';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$5 } from '@dbh/countries-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$3 } from '@dbh/development-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$6 } from '@dbh/language-provider-redux';
import { REDUX_AND_SAGA_KEY } from '@dbh/logger-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$2 } from '@dbh/with-entry-content';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$4 } from '@dbh/webcontrols-events-and-constants-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$7 } from '@dbh/hotels-redux';
import 'redux';
import Sentry$1 from '@dbh/sentry';
import throwInServerSideRendering from '@dbh/throw-in-server-side-rendering';
import * as Sentry from '@sentry/react';
import 'react-immutable-proptypes';
import _includes from 'lodash/includes';

/**
 * Appends additional tags to the data that wil be sent to `Sentry`.
 * Use this function to add additional tags that cannot be statically set.
 * @param {Object?} sentryData Payload that will be sent to `Sentry`.
 * @return {Object} A new copy of the payload passed as argument, with additional
 * tags added. It returns the original input if it is not possible to append
 * tags.
 */const addSentryTags=withConformsTo("addSentryTags",[],a=>{if(!a)return a;const b=a.extra?a.extra.state:void 0;// It is possible that an exception has occurred before everything is set up
// correctly. In this case we cannot extract additional data from the store.
if(!b)return a;let c,d,e,f,g;// It is possible that the gathering of data through selectors could raise
// exceptions: e cannot let them surface here, or the logging of the first
// exception to `sentry` would fail.
try{c=makeSelectTemplateCode()(b),e=makeSelectIsFirstPageAfterSsr()(b),d=makeSelectReactRouterPageType()(b),f=makeSelectCountry()(b),g=makeSelectIsUserSignedIn()(b);}catch(b){// An error has occurred while extracting data from the selectors
// We cannot proceed further.
return a}const{tags:h,...i}=a;return {...i,tags:{...h,templateCode:c,isFirstPageAfterSsr:e,reactRouterPageType:d,country:f,isUserSignedIn:g}}},{allowUnusedParameters:!0});var addSentryTags$1 = addSentryTags;

const selectErrorsApi=makeSelectApiUrl(API_IDS.errors);var selectErrorsApi$1 = selectErrorsApi;

const stateKeysDoNotSendToSentry=[REDUX_AND_SAGA_KEY,REDUX_AND_SAGA_KEY$1,REDUX_AND_SAGA_KEY$2,REDUX_AND_SAGA_KEY$3,REDUX_AND_SAGA_KEY$4,REDUX_AND_SAGA_KEY$5,REDUX_AND_SAGA_KEY$6,REDUX_AND_SAGA_KEY$7,REDUX_AND_SAGA_KEY$8];const defaultOptions={sendStoreToApiErrors:!1// We leave `maximumApiErrorsCalls` undefined by default, since in server side
// rendering, at the moment, we "make" the callback "one time" for the entire
// session that can serve thousands of requests ... a limit doesn't make too
// much sense; when `maximumApiErrorsCalls` is undefined, the calls to the API
// are "unlimited".
};const IS_SENTRY_ENABLED_IN_THE_BROWSER=!0;

// send the state to our internal logging service, while sending only a reference
// of the state to `sentry`.
const sendSentryDataToLoggingService=withConformsTo("sendSentryDataToLoggingService",[],a=>{const{sendStoreToApiErrors:b,maximumApiErrorsCalls:c}={...defaultOptions,...a};// If `maximumApiErrorsCalls` is undefined the API calls are "unlimited".
c!==void 0&&invariant(!_isNaN(c)&&0<c,"Expected `maximumApiErrorsCalls` to be a positive Number instead of: "+c+".");let d=0;return a=>{// Generate the state `uuid` client-side, can't have the server generate it
// because this function needs to be synchronous.
const e=crypto.randomUUID(),f=a.extra?a.extra.state:void 0,g=API_IDS_TO_VERSIONS[API_IDS.errors]||API_IDS_TO_VERSIONS.default;let h;try{// Try to use `selectErrorsApi`, but it might fail on some cases (e.g. the
// error happens before the locale/country is available).
h=selectErrorsApi$1(f);}catch(a){h=makeApiUrl(API_IDS.errors,{routeParameters:fromJS({locale:DEFAULT_LOCALE,country:DEFAULT_COUNTRY,apiVersion:g})});}// Get the user agent from the state.
const j=f?getIn(f,["route","computedDataFromServerSideRendering","userAgent"]):"(not set)",k=makeApiUrl(API_IDS.errors,{routeParameters:fromJS({locale:DEFAULT_LOCALE,country:DEFAULT_COUNTRY,apiVersion:g,id:e})}),l=makeApiUrl(API_IDS.errors,{routeParameters:fromJS({locale:DEFAULT_LOCALE,country:DEFAULT_COUNTRY,apiVersion:g,id:e}),queryParameters:Map({_fields:"store"}),encodeQueryWithRison:!1}),m="undefined"!=typeof a.exception,n={...a,extra:{...a.extra,state:{guid:e,details:k,"redux-store":l},isSsr:isServerSideRendering(),exceptionProvided:m}};// Get the urls that we will send to sentry. `detailsApiUrl` is a link to the full
// object while `storeApiUrl` adds a parameter that makes the url only show the store.
// Create a new data object that replaces the state with the reference to it.
// When `dataSentry.exceptions` does not have an exception we send a generic one.
// See: https://sentry.io/daybreakhotels-srl/daybreakhotels/issues/572458411/
let o;if(m)o=a.exception.values;else {const a={value:new Error("Sentry Logger: Exception not provided, guid: "+e+".")};o=[a];}const p=b?a.extra.state:"";// It's usually just one exception but `sentry` can potentially bundle
// multiple exceptions in one pass. Send a request to our logging api for
// each one.
for(let b=0,f=o.length;b<f&&!(d>=c);b+=1){const a=o[b];requestWithBodyPOST(h,{error:a.value,store:p,guid:e,isSsr:isServerSideRendering(),exceptionProvided:m,userAgent:j}).catch(a=>{// If for some reason the API replies with 404 or 500, the `async`
// function `requestWithBodyPOST` "throws", but we can't catch that
// with "try/catch", we have to use `.catch`. Why? It's not a "standard
// throw": `requestWithBodyPOST` is returning a rejected `Promise`!
// A rejected `Promise` will propagate up in the stack unless you catch
// it, creating an endless cycle, since `sentry` catches it and calls
// this function again and again.
// @see {@link https://www.valentinog.com/blog/throw-errors-async-functions-javascript/}
// eslint-disable-next-line no-console
console.error("(sendSentryDataToLoggingService) Request to API `/errors` failed:",a);}),d+=1;}return n}});var sendSentryDataToLoggingService$1 = sendSentryDataToLoggingService;

/**
 * Removes super big data (or private data) in the state that would cause `sentry`
 * to reject the requests made by `sentry`, since the current limit is `100KB` for
 * "breadcrumbs" and extra data that `@sentry/react` adds, starting from the
 * `redux` store state.
 * @param {Immutable.Map} state .
 * @return {Immutable.Map} .
 * @see {@link https://docs.sentry.io/platforms/javascript/guides/react/features/redux/#redux-enhancer-options}
 */const stateTransformer=withConformsTo("stateTransformer",[],a=>a.deleteAll(stateKeysDoNotSendToSentry));var stateTransformer$1 = stateTransformer;

/**
 * Returns filtered out `redux` form events, too many to log them all.
 * @param {Object?} event .
 * @return {boolean} .
 * @see {@link https://docs.sentry.io/platforms/javascript/guides/react/features/redux/#redux-enhancer-options}
 */const filterBreadcrumbEvents=withConformsTo("filterBreadcrumbEvents",[],a=>{let{type:b}=a;return !_includes(b,"@@redux-form")});var filterBreadcrumbEvents$1 = filterBreadcrumbEvents;

/**
 * Creates and returns the `sentry` middleware for `redux`.
 * @return {Function} The middleware.
 * @see {@link https://docs.sentry.io/platforms/javascript/guides/react/features/redux/#redux-enhancer-options}
 */const createSentryReduxMiddleware=()=>Sentry.createReduxEnhancer({stateTransformer: stateTransformer$1,actionTransformer:filterBreadcrumbEvents$1});var createSentryReduxMiddleware$1 = createSentryReduxMiddleware;

const fnName="initializeSentryBrowserAndGetMiddleware",initializeSentryBrowserAndGetMiddleware=()=>{if(throwInServerSideRendering(fnName),!IS_SENTRY_ENABLED_IN_THE_BROWSER);// @see {@link https://docs.sentry.io/clients/javascript/config/#optional-settings}
const a=a=>{try{const b=getIn(a,["exception","values"],[]);for(const a of b){const b=a&&a.value;if("string"==typeof b&&b.includes("oneTapSignInResponsePropType"))return !1}}catch(a){}// If we do not return \`true\`, we end up swallowing exceptions.
return !0},b=addSentryTags$1;// The store is currently `1 MB` and more, so in certain cases (heavy traffic
// and lots of errors) we may not want to send it to the server, to avoid
// overloading it. This is an environment variable that can be set at build
// time or runtime.
// In case of "recursive errors" or similar malfunctions in this app, even just
// on certain browsers, we don't want to overload the back end with too many API
// calls to `errors` from a single client's session. `DBH_SHOULD_CALL_API_ERRORS`
// is an environment variable that can be set at build time or runtime.
return Sentry$1.onLoad(()=>{Object.assign(Sentry$1,window.Sentry);const c={hostname:window.location.hostname,commit:"a7d19723476534abb701ed1b3f7fed2e51518b95",isServerSideRendering:!1,isProductionWWW:!0};Sentry$1.init({// No need to add `dsn` for the browser, `Sentry` is lazy loaded through
// inline script in `@dbh/html-document` using the public key.
// dsn: process.env.SENTRY_RAVEN_PUBLIC_DSN,
environment:"production",release:"2025-02-14T20_54_47-a7d19723476534abb701ed1b3f7fed2e51518b95-production-bitbucket-8833",maxBreadcrumbs:80,beforeSend:c=>{const d=a(c);return d?b(c):null},integrations(a){return [...a.filter(a=>"Breadcrumbs"!==a.name),new Sentry$1.Integrations.Breadcrumbs({dom:!1,console:!1})]},initialScope:{tags:c}});}),createSentryReduxMiddleware$1()};var initializeSentryBrowserAndGetMiddleware$1 = initializeSentryBrowserAndGetMiddleware;

export { IS_SENTRY_ENABLED_IN_THE_BROWSER, addSentryTags$1 as addSentryTags, createSentryReduxMiddleware$1 as createSentryReduxMiddleware, initializeSentryBrowserAndGetMiddleware$1 as initializeSentryBrowserAndGetMiddleware, sendSentryDataToLoggingService$1 as sendSentryDataToLoggingService };
